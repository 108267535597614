<template>
    <edit-item
        :baseUrl="baseUrl"
        :submitBtnTitle="submitBtnTitle"
        :title="title"
    />
</template>

<script>
import EditItem from './../components/EditItem'

export default {
    components: {
        EditItem
    },
    data() {
        return {
            submitBtnTitle: this.$t('messages.terminalReviewUpdate'),
            title: this.$t('messages.terminalReviewUpdate'),
            baseUrl: '/terminal-reviews',
        }
    },
}
</script>
